import { render, staticRenderFns } from "./ProductDlivyRegistration.vue?vue&type=template&id=353ace4a&scoped=true&"
import script from "./ProductDlivyRegistration.vue?vue&type=script&lang=js&"
export * from "./ProductDlivyRegistration.vue?vue&type=script&lang=js&"
import style0 from "./ProductDlivyRegistration.vue?vue&type=style&index=0&id=353ace4a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "353ace4a",
  null
  
)

export default component.exports