<template>
  <div class="content-wrapper">
    <div class="content-lookup">  <!-- 필터 -->
      <div class="lookup-left">
        <ul class="lookup-condition">
          <!-- 범위 날짜 검색 -->
          <li class="field">
            <div class="title"> 출고 일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range type="lookup-condition"  v-model="searchFilterOptions.dlivyDate"/>
              </li>
            </ul>
          </li>
          <!--  여러개 드롭다운   -->
          <li class="field">
            <div class="title"> 출고 창고</div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect ref="wrhous" v-model="searchFilterOptions.wrhous"
                                 :dataSource="commonCodeOptions.wrhousData" :fields="commonCodeOptions.fields"
                                 :allowFiltering="false" cssClass="lookup-condition-multiselect" width="300"/>
              </li>
            </ul>
          </li>
          <!-- 텍스트 한개   -->
          <li class="field">
            <div class="title"> 품명</div>
            <ul class="content">
              <li class="item">
                <input-text v-model="searchFilterOptions.productName" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title"> 구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist ref="bcncMultiSelect" v-model="searchFilterOptions.dlivyDiv"
                                  :dataSource="commonCodeOptions.dlivyDiv"
                                  :fields="commonCodeOptions.fields" :allowFiltering="false"
                                  cssClass="body-data-dropdown"/>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET"  :ignore="isShowProductPopup" :is-shortcut-button="true" @click.native="onClickSearch()">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button"></ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article" :class="isOpen ? 'detail-view-opened' : ''">
        <div class="article-left">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{ gridField.title }}</div>
                <div class="header-caption">[{{ gridField.count }}건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="add">
                    <erp-button button-div="GET" :is-icon-custom="true" @click.native="onClickAdd">추가</erp-button>
                  </li>
                  <li class="print">
                    <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="grid"
                    v-bind="initGrid"
                    @actionComplete="gridComplete"
                    @recordClick="onGridClick"
                />
              </div>
            </div>
          </section>
        </div>
        <div class="article-right">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">출고 상세</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="save keyColor">
                    <erp-button
                        button-div="SAVE"
                        :is-key-color="true"
                        :is-shortcut-button="true"
                        :ignore="isPopupOpened"
                        @click.native="onClickSave"
                    >
                      저장
                    </erp-button>
                  </li>
                  <li class="delete">
                    <erp-button
                        v-if="!isNew"
                        button-div="DELETE"
                        :is-key-color="true"
                        :is-shortcut-button="true"
                        :ignore="isPopupOpened"
                        @click.native="onClickDelete"
                    >
                      삭제
                    </erp-button>
                  </li>
                  <li class="close">
                    <erp-button
                        button-div="CLOSE"
                        @click.native="onClickClose"
                    >
                      닫기
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="article-section" style="height: 140px;">
                <div class="body-data">
                  <div class="data-outer">
                    <div class="data-inner">
                      <ul class="data-content">
                        <!-- detail field -->
                        <li class="field">
                          <div class="title">
                            출고 번호
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-text id="detailNum" ref="detailNum"
                                              v-model="detailData.dlivyId" :readonly="true"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title required">
                            출고 일자
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-date ref="dlivyDate" type="lookup-condition"  :disabled="!isNew"
                                              v-model="detailData.dlivyDate"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title required" >
                            출고 구분
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <ejs-dropdownlist   ref="dlivyDiv" v-model="detailData.dlivyDiv" :dataSource="commonCodeOptions.dlivyDiv" :fields="commonCodeOptions.fields" cssClass="body-data-dropdown"
                                                      :readonly="!isNew"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title required" >
                            출고 창고
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <ejs-dropdownlist  ref="wrhousCode" v-model.lazy="detailData.wrhousCode" :dataSource="commonCodeOptions.wrhousData" :fields="commonCodeOptions.fields" cssClass="body-data-dropdown"
                                                     :readonly="!isNew2" />
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field " v-if="detailData.dlivyDiv=='D2'"> <!-- 이동일때만 입고창고가 보이게 한다. -->
                          <div class="title required" >
                            입고 창고
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <ejs-dropdownlist  ref="inWrhous" v-model="detailData.inWrhous" :dataSource="commonCodeOptions.wrhousData" :fields="commonCodeOptions.fields" cssClass="body-data-dropdown"
                                                     :readonly="!isNew"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field voc-section">
                          <div class="title">
                            비고
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-text ref="remarks"
                                              v-model="detailData.remarks"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title">
                            등록일자
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-text ref="insertDt" v-model="detailData.insertDt" :readonly="true"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title">
                            등록자
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-text ref="insertName" v-model="detailData.insertName" :readonly="true"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="article-section" style="height:calc(100% - 140px); overflow-y:hidden">
                <section class="article-section">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">{{ gridField2.title }}</div>
                      <div class="header-caption">[{{ gridField2.count }}건]</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add">
                          <erp-button button-div="GET"  @click.native="onClickAddProduct">행추가</erp-button>
                        </li>
                        <li class="delete">
                          <erp-button button-div="DELETE" @click.native="onClickDelProduct">행삭제</erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid table-side">
                      <ejs-grid-wrapper ref="grid2" v-bind="initGrid2"
                                        @headerCellInfo = "onHeaderCellInfo2"
                                        @queryCellInfo="onQueryCellInfo2"
                                        @actionComplete="gridComplete2"/>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </article>

    </div>
    <!-- 각종팝업 -->
    <product-stock-search-popup
        ref="productSearchPopup"
        v-if="isShowProductPopup"
        v-bind:wrhousCode="detailData.wrhousCode"
        @popupConfirmed="onPopupConfirmed"
    />
  </div>
</template>
<script>

import inputDateRange from "@/components/common/datetime/InputDateRange.vue";
import inputText from "@/components/common/text/InputText.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import moment from "moment";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Aggregate, Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import inputDate from "@/components/common/datetime/InputDate.vue";
import StockProductMng from "@/api/v2/StockManage/StockProductMng";
import {compareDate, getFormattedDate, getTodayDateTime} from "@/utils/date";
import {mapGetters} from "vuex";
import {validateFormRefs} from "@/utils/formUtil";
import productStockSearchPopup from "@/views/stock-management/common/ProductStockSearchPopup.vue";
import StockCommon from "@/api/v2/StockManage/StockCommon";


export default {
  name: "ProductDlivyRegistration",
  mixins: [commonMixin, messagePopupDialogMixin],
  components: {productStockSearchPopup, inputDate, ejsGridWrapper, ErpButton, inputText, inputDateRange},

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      //new edit start
      isOpen: false,
      isShowProductPopup:false,
      searchFilterOptions: {
        dlivyDate: {
          //from: getAddDays(new Date(),-30),
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        },
        productName: null,
        wrhous: [],
        dlivyDiv: null,
      },
      //드롭다운 코드
      commonCodeOptions: {
        wrhousData: [],
        dlivyDiv: commonCodesGetCommonCode("DLIVY_DIV",false,true,"","").filter((code)=> code.comCode!='D4'),
        fields: {text: "comName", value: "comCode"},
      },

      gridField: {
        title: "출고 목록",
        count: 0,
        dataSource: [],
      },
      //detail
      detailData: {
        dlivyId: null,
        dlivyDate: null,
        dlivyDiv: "D2",
        wrhousCode: null,
        inWrhous: null,
        remarks: null,
        delFlag: false,
        insertId: null,
        insertName: null,
        updateId: "",
        updateDt: "",

        dtlListD:[],
        dtlListU:[],
        dtlListI:[],
      },
      //validate 체크 항목
      validateRefField: {
        dlivyDate: {required: true,},
        wrhousCode: { required: true },
        dlivyDiv: { required: true },
      },
      gridField2: {
        title: "출고 품목",
        count: 0,
        dataSource: [],
      },
    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    ...mapGetters(["userIp","userId","username"]),
    isPopupOpened() {
      return (!this.isOpen||this.isShowProductPopup);
    },
    isNew(){
      //신규입력이면
      return this.detailData.dlivyId === null;
    },
    isNew2(){
      //신규입력이면서 추가된 행이 없을경우만 창고수정가능
      return (this.detailData.dlivyId===null && this.gridField2.count === 0);
    },
    initGrid() {
      return {
        columns: [
          {field: 'dlivyId',       textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'number', headerText: '출고번호',},
          {field: 'dlivyDiv',      textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'string', headerText: '출고구분', isCommonCodeField: true, dataSource: this.commonCodeOptions.dlivyDiv,},
          {field: 'dlivyDate',     textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'string', headerText: '출고일자',
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          {field: 'wrhousCode',    textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'string', headerText: '출고창고', isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousData,},
          {field: 'inWrhous',      textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'string', headerText: '입고창고', isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousData,},
          {field: 'qty',           textAlign: 'right',  width: 100, visible: true,   allowEditing : false,  type: 'number', headerText: '품목건수', isNumericType: true,  },
          {field: 'remarks',       textAlign: 'left',   width: 150, visible: true,   allowEditing : false,  type: 'string', headerText: '비고'},
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay: true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: {pageSize: 50},
        resizeSettings: {mode: "Normal"},

      };
    },
    initGrid2() {
      return {
        columns: [
          {field: 'productCode', textAlign: 'center', width: 60, visible: true,   allowEditing : false, type: 'number', headerText: '상품코드', isPrimaryKey: true},
          {field: 'productName', textAlign: 'center', width: 50, visible: true,   allowEditing : false, type: 'string', headerText: '상품명', },
          {field: 'unit',         textAlign: 'center', width: 50, visible: true,   allowEditing : false, type: 'string', headerText: '단위',  },
          {field: 'qty',          textAlign: 'right',  width: 40, visible: true,   allowEditing : true, type: 'number', headerText: '수량' ,
            isNumericType: true,  inputNumberProperty: {allowDot:true ,maxUnderDotLength: 3 },}, // editType:'numericedit', format: 'N2', // 그리드에서 버튼으로 숫자 조정시 세팅
          /*{field: 'qty',          textAlign: 'right',  width: 40, visible: true,   allowEditing : true, type: 'number', headerText: '수량' , isNumericType: true,}, // editType:'numericedit', format: 'N2', // 그리드에서 버튼으로 숫자 조정시 세팅*/
          { field: "remarks",      textAlign: 'left',   allowEditing: true,  width: 150, visible: true,  type: "string",  headerText: "비고", },
        ],

        provides: [Aggregate,ExcelExport,Filter, Resize, Page, ForeignKey, Edit,],
        dataSource: this.gridField2.dataSource,

        allowResizing: true,
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: false,
        isShowProgress: true,
        isNOColumnDisplay: false,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: {pageSize: 50},
        resizeSettings: {mode: "Normal"},

        //그리드 정합성 체크
        validationRules: {
          productCode:  {required: true, duplicateCheck: true},
          qty:    {required: true, min:0.1},
          //qty:    {required: true, min:1},   //출고시는 1이상으로 하자.가격이 정수가 안나옴.
        },
      };

    },
  },

  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
    // 기초 데이터 초기화
    this.clearDetailData();
  },
  async mounted() {
    //기초데이터 세팅
    await this.initData();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //region ******************************* 공통 function *******************************
    validateFormRefs,
    //기초데이터 세팅
    async initData() {
      // 출고일자 min,max 설정
      const datePickerInstance = this.$refs.dlivyDate.$refs.datepicker.ej2Instances;
      //datePickerInstance.min = await StockCommon.getLastProductClosingYm();// 최종 마감일자
      datePickerInstance.max = getFormattedDate(new Date());
      // 창고
      this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'Y',authoFlag:'Y',suppliesFlag:'N'});
    },
    clearDetailData() {
      this.detailData = {
        dlivyId: null,
        dlivyDate: getFormattedDate(new Date()),
        dlivyDiv: "D2",
        wrhousCode: null,
        inWrhous: null,
        remarks: null,
        delFlag: false,
        insertId: null,
        insertDt: getTodayDateTime(new Date()),
        insertName: this.username,

        dtlListD: [],
        dtlListU: [],
        dtlListI: [],
      };
      this.gridField2.dataSource = [];
    },
    //endregion
    //region******************************* 버튼 이벤트 *******************************
    //리스트조회
    async onClickSearch(){
      this.onClickClose();
      const args = {
        dlivyDateFrom: this.searchFilterOptions.dlivyDate.from,
        dlivyDateTo: this.searchFilterOptions.dlivyDate.to,
        productName: this.searchFilterOptions.productName,
        wrhousList: this.searchFilterOptions.wrhous.join('|'),
        dlivyDiv: this.searchFilterOptions.dlivyDiv,
      };
      // 조회조건에 있는 창고만.
      if (args.wrhousList === "") {
        args.wrhousList = this.commonCodeOptions.wrhousData.map(data => data.comCode).join('|');
      }
      this.gridField.dataSource = await StockProductMng.getProductDlivy(args);
    },
    //추가등록
    onClickAdd(){
      this.isOpen = true;
      this.clearDetailData();
    },
    //엑셀
    onClickExcel(){
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //저장
    async onClickSave() {
      if (!this.validateFormRefs(this.validateRefField)) {
        return;
      }
      //미래 일자 체크
      if (compareDate(getFormattedDate(new Date()), this.detailData.dlivyDate) < 0) {
        this.errorToast("출고일자는 미래일자로 등록할 수 없습니다.");
        this.$refs.dlivyDate.focus();
        return;
      }
      //입고창고 체크
      if(this.detailData.dlivyDiv !='D2'){
        this.detailData.inWrhous = null;
      }
      if(this.detailData.wrhousCode === this.detailData.inWrhous){
        this.errorToast("출고창고와 입고창고가 동일합니다.");
        return;
      }
      //품목 건수 체크
      if(this.gridField2.count === 0){
        this.errorToast("출고할 품목을 1건 이상 등록하세요.");
        return;
      }
      //그리드 필수 입력체크
      if (this.$refs.grid2.isGridModified() && !this.$refs.grid2.validate()) {
        return;
      }
      const {addedRecords, changedRecords, deletedRecords,} = this.$refs.grid2.getBatchChanges();
      this.detailData.dtlListD = deletedRecords;
      this.detailData.dtlListU = changedRecords;
      this.detailData.dtlListI = addedRecords;

      if(this.isNew) {
        await StockProductMng.postProductDlivyProduct(this.detailData);
      }else{
        await StockProductMng.patchProductDlivyProduct(this.detailData);
      }
      this.infoToast(this.$t('main.popupMessage.saved'));
      await this.onClickSearch();
    },
    //삭제
    async onClickDelete(){
      if (!(await this.confirm(this.$t('main.popupMessage.confirmDelete')))) {
        return;
      }
      await StockProductMng.deleteProductDlivyProduct({deleteKey:this.detailData.dlivyId, deleteDate:this.detailData.dlivyDate});
      this.infoToast(this.$t('main.popupMessage.deleted'));
      await this.onClickSearch();
    },
    //닫기
    onClickClose(){
      this.isOpen = false;
      this.clearDetailData();
    },
    //행추가
    onClickAddProduct(){
      if(this.detailData.wrhousCode == null){
        this.errorToast("출고창고를 먼저 선택하세요.");
        return;
      }
      this.isShowProductPopup = true;
    },
    //행삭제
    onClickDelProduct(){
      this.$refs["grid2"].deleteRecord();
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    },
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    gridComplete2(){
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },
    async onGridClick(args){
      this.clearDetailData();
      const {rowData} = args;

      this.isOpen = true;
      this.detailData = await StockProductMng.getProductDlivyDtl({dlivyId:rowData.dlivyId});
      this.gridField2.dataSource = await StockProductMng.getProductDlivyProduct({dlivyId:rowData.dlivyId});
    },
    onHeaderCellInfo2(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.grid2.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onQueryCellInfo2(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    //endregion
    //region******************************* 팝업 이벤트 *******************************
    onPopupConfirmed(args){
      args && args.forEach((record)=>{
        /*if(this.$refs.grid2.getBatchCurrentViewRecords().findIndex(v => v.productCode === record.productCode)>=0) {
          return;
        }*/
        this.$refs.grid2.addRecord({
          dlivyId   : this.detailData.dlivyId,
          productCode : record.productCode,
          productName : record.productName,
          unit         : record.unit,
          qty          : record.dlivyQty,
          oldQty       : 0 , //record.qty,
          remark       : null,
        });
      });
      this.isShowProductPopup = false;
      //ROW- COUNT
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    }
    //endregion
  },

};

</script>
<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-50% - 0px)}
body .appContent .body-article.detail-view-opened .article-left{width:calc(50% - 0px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(50% - 0px); margin:0}
body .body-footer .section-header {
  display: block;
  position: relative;
  margin: 0px 0px;
}
.voc-section {
  width: 100% !important;
}

</style>